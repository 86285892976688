import "./App.css";

 import React from "react";
import Navigate from "./navigation/Navigate";

function App() {
  return (
    <div>
        <Navigate/>
    </div>
  );
}

export default App;
