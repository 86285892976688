import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import "./About.css";
import tazeen from "../../Assets/tazeen.jpeg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Popup2(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <img src={props.img} alt="" onClick={handleClickOpen} />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className="PopupDiv">
          <div className="TeamContents">
            <div className="teamPopupImg">
              <img src={tazeen} alt="" />
            </div>
            <div className="title">
              <Button
                autoFocus
                color="inherit"
                onClick={handleClose}
                className="popupClose"
              >
                <CloseIcon />
              </Button>
              <h2>Dr Tazeen Ashraf</h2>
              <br />
              <h4>Vice President</h4>
            
              <h6>EFOG-EBCOG, FCPS OBG, MBBS</h6>
            </div>
          </div>
          <div className="AboutTeam">
            <p>
              Hailing from Lahore, Pakistan; Dr Tazeen Ashraf is a renowned OB
              GYN specialist with enormous clinical experience and medical
              knowledge. She joins the International Coordinating Committee of
              EBCOG (ICC EBCOG) as the first vice president of the organization.{" "}
              <br />
              <br />
              Dr Tazeen is currently working as a specialist in the OB GYN
              department at Evercare Hospital Lahore (GATE's CAREWAY Pakistan).
              Amidst her busy schedule, she remains passionate towards mentoring
              and is also known for her teaching and mentoring skills.
              <br />
              <br />
              She believes in delivering classes with top quality in a way it
              makes learning a simpler process for the students. She has
              conducted and led various national and international seminars,
              workshops, and paper presentations.
            </p>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default Popup2;
