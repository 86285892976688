import React, { useState } from 'react';
import axios from 'axios';
function ImageUploader() {
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageName, setImageName] = useState('');
  
    const handleImageChange = (event) => {
      setSelectedImage(event.target.files[0]);
    };
  
    const handleNameChange = (event) => {
      setImageName(event.target.value);
    };
  
    const handleUpload = async () => {
      try {
        const formData = new FormData();
        formData.append('imageData', selectedImage);
        formData.append('imageName', imageName);
  
        const response =  await axios.post('http://localhost:5000/image/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        
      const imagePath = response.data.document.imageData.replace(/\\/g, '/'); // Convert backslashes to forward slashes

      console.log('Image uploaded successfully');
      console.log('Image path:', imagePath);

  
        setSelectedImage(null);
        setImageName('');
      } catch (error) {
        console.error(error);
      }
    };
  
    return (
      <div>
        <h1>Image Uploader</h1>
        <label htmlFor="image">image
        <input type="file" onChange={handleImageChange} placeholder='image' id="image"/></label>
        <input type="text" placeholder="Image Name" value={imageName} onChange={handleNameChange} />
        <button onClick={handleUpload}>Upload</button>
      </div>
    );
  }
  
  export default ImageUploader;
  