import React, { useEffect } from "react";
import "./vision.css";
import Grid from "@mui/material/Grid";
import img from "../../Assets/events.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

function Vision() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <Grid container spacing={0}>
        <Grid item sm={6} className="visionContainerHome">
          <div data-aos="fade-right" data-aos-easing="ease-in-sine">
            <br />
            <br />
            <h2>Vision</h2>
<br />
            <p>
              The International Coordinating Committee - ICC EBCOG follows the same
              mission and vision of EBCOG. The committee aims to promote the
              highest possible standards of care to women, unborn and newborn so
              as to ensure and improve their health standards.
              <br /><br />
              The Committee holds it as the prime responsibility to spread the
              vision and mission of EBCOG to the farthest countries across the
              globe. It also aims to conduct various training programs for
              uplifting the skills of medical professionals especially the OB
              GYN.
            </p>
          </div>
        </Grid>
        <Grid item sm={6}>
          <div className="container">
            <div data-aos="fade-right" data-aos-easing="ease-in-sine">
              <img src={img} alt="" className="visionImg" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Vision;
