import React, { useState } from "react";
import "./Events.css";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import { eventsData } from "./EventsData";
import { Link } from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import img from '../../Assets/event3.jpg'

function EventsPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);
  const indexOfLastBlog = currentPage * postsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - postsPerPage;
  const currentBlogs = eventsData.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <Navbar />
      <div className="eventBanner"></div>
      <div className="eventsPage">
        {currentBlogs.map((item, index) => {
          return (
            <div className="eventCard" key={index}>
              <div className="eventImage">
              <Link to={`/event/page/${item.id}`}>
                <img src={img} alt="" />
                </Link>
              </div>

              <div className="eventContents">
                <Link to={`/event/page/${item.id}`}>
                  <h2>{item.Title}</h2>
                </Link>
                <br />
                <p>{item.content}</p>
                <br />
                <div className="dateDiv">
                  <div>
                    Date: <b>{item.Date}</b>
                    <br /><br />
                    {/* <RegisterPopup className={"trainingButtonStyle"} button={'Register now'}  title={"Register now"}/> */}
                  </div>

                  {/* <Counter date={item.date} seconds={item.seconds} /> */}
                </div>
              </div>
            </div>
          );
        })}
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={eventsData.length}
          paginate={paginate}
        />
      </div>
      <Footer />
    </>
  );
}

export default EventsPage;
