export const GalleryData=[
    {
        img1: require("../../Assets/event1.jpg").default,
        img2: require("../../Assets/bg.png").default,
        img3:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5NLL9Rb5BRvGb4aPxntbaEcYLYHlYAYYUX8oVyo9x_1T0gGPc0rZzYcJ_JUn0isdTdE64oYNhcKA&usqp=CAU&ec=48600113",
        img4:"https://studymedic.com/wp-content/uploads/2023/03/photo_2023-03-08_14-49-00.jpg"
    }
]

// export const GalleryData2=[
//     {
//         img1:"https://www.shutterstock.com/image-photo/business-entrepreneurship-concept-speaker-giving-260nw-1559872439.jpg",
//         img2:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTR8hS5gYzPIE6sFzSnIS6LI4QG2ihLvnJyHfCpUp7zAA&usqp=CAU&ec=48600113",
//         img3:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5NLL9Rb5BRvGb4aPxntbaEcYLYHlYAYYUX8oVyo9x_1T0gGPc0rZzYcJ_JUn0isdTdE64oYNhcKA&usqp=CAU&ec=48600113",
//         img4:"https://studymedic.com/wp-content/uploads/2023/03/photo_2023-03-08_14-49-00.jpg"
//     }

// ]



  