export const trainingData=[
    {
        course:"Gastroenterology",
        name:"Dr. Teenu Maria",
        date:"19 April 2023",
        time:"20:00 HRS IST (14:30 HRS GMT)"
     },
    // {
    //     course:"Gastroenterology",
    //     name:"Dr. Reenu Maria",
    //     date:"19 April 2023",
    //     time:"20:00 HRS IST (14:30 HRS GMT)"
    // },
    // {
    //     course:"Gastroenterology",
    //     name:"Dr. Meenu Maria",
    //     date:"19 April 2023",
    //     time:"20:00 HRS IST (14:30 HRS GMT)"
    // },
]