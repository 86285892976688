import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import RegisterPopup from "./RegisterPopup";

function ViewMore() {
  return (
    <div>
      <Navbar />
      <div className="viewMoreContainer">
        <div className="viewMoreContainerImg">
          <img src="https://studyplab.com/wp-content/uploads/2022/08/Plab-exam-1-14-days.jpg" alt="" />
        </div>
        <div className="viewMoreContainerContent">
        <h1>Title</h1>
        <br />
        <p>
         Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore, odio, iste tenetur praesentium maiores reiciendis amet pariatur cupiditate quia quaerat ea animi commodi voluptatibus error iure, beatae consectetur. Inventore, pariatur!
         </p>
         <br />
         <div className="time">
         <h5>Date: 06/04/2023</h5>
         <br />
         <h5>Time: 10.30 AM</h5>
         <br />
         </div>
         <RegisterPopup className='buttonStyle' button={"Register Now"} title={"Register now"}/>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ViewMore;
