import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import "./About.css";
import Dhavashree from "../../Assets/dhavasree.jpeg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Popup3(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <img src={props.img} alt="" onClick={handleClickOpen} />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className="PopupDiv">
          <div className="TeamContents">
            <div className="teamPopupImg">
              <img src={Dhavashree} alt="" />
            </div>
            <div className="title">
              <Button
                autoFocus
                color="inherit"
                onClick={handleClose}
                className="popupClose"
              >
                <CloseIcon />
              </Button>
              <h2>Dr Dhavashree V</h2>
              <br />
              <h4>Treasurer </h4>
            
              <h6>EFOG-EBCOG, MRCOG, DGO, MBBS</h6>
            </div>
          </div>
          <div className="AboutTeam">
            <p>
            A reproductive medicine and infertility specialist from India, Dr Dhavashree V joins the leadership team of the International Coordinating Committee of EBCOG (ICC EBCOG) as its first treasurer.
              <br />
              <br />
              She is a highly qualified Obstetrician and Gynaecologist specialised in laparoscopy, fertility and assisted reproduction. She is presently practising as an OB GYN Consultant with SKS Hospital and is the Clinical Director of SKS Women’s Centre and Fertility Foundation.
              <br />
              <br />
              A dedicated and passionate teacher, Dr. Dhavashree finds time to do mentoring for a number of international medical memberships including EFOG-ECBOG. She is actively involved in coordinating and conducting seminars on various OBGYN topics and is committed to make learning a more fruitful and extensive experience for her students.
            
              <br /><br />
              Qualifications and Memberships
            <ul>
              <li>	EFOG-EBCOG: The European Fellowship in Obstetrics and Gynaecology by the European Board & College of Obstetrics and Gynaecology.</li>
              <li>	Member of Royal College of Obstetricians and Gynaecologists - MRCOG UK.</li>
              <li>	DLS & DRM Gynae Laparoscopy Training at CIMAR, Kerala and from the Kiel University, Germany.</li>
              <li>  ART: Training in the field of Assisted Reproduction from National University Hospital, Singapore and from Kiel University, Germany.</li>
              <li>	DGO: Kasturba Medical College, Manipal.</li>
              <li>	MBBS: PSG institute of Medical Sciences and Research, India.</li>
<br /><br />
            </ul>
            </p>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default Popup3;
