import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Banner from "../../components/banner/Banner";
import Vision from "../../components/vison/Vision";
import Events from "../../components/Events/Events";
import Slideshow from "../../components/partners/Partners";
import Branch from "../../components/branches/Branch";
import Footer from "../../components/footer/Footer";
import Gallery from "../../components/Gallery/Gallery";

function Home() {
  return (
    <div>
      <Navbar />
      <Banner />
      <Vision/>
      <Events/>
      <Branch/>
      <Slideshow/>
      <Gallery/>
      <Footer/>
    </div>
  );
}

export default Home;
