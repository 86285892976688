import React, { useState } from "react";
import "../blogs/Blogs.css"
import Axios from "axios";
import Button from "@mui/material/Button";

function TrainingCourses() {
  const url = "http://localhost:5000/api/training/details/";
  const [course, setCourse] = useState("");
  const [mentor, setMentor] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    Axios.post(url, {
      course: course,
      mentor: mentor,
      date: date,
      time: time, 
    }).then((res) => {
      console.log("success", res);
    })
    .catch(error => {
      console.log('Error message:', error.message);
    });
  };
  return (
    <div className='adminBlogContainer'>
      <form action="" onSubmit={handleSubmit} className='adminBlogForm'>
    
            <input
              id="course"
              placeholder="Course name"
              variant="standard"
              onChange={(e) => setCourse(e.target.value)}
              className='BlogFormInput'
            />
         
            <input
              id="mentor"
              placeholder="Mentor"
              variant="standard"
              onChange={(e) => setMentor(e.target.value)}
              className='BlogFormInput'
            />
         
            <input
              id="date"
              placeholder="Date"
              variant="standard"
              onChange={(e) => setDate(e.target.value)}
              className='BlogFormInput'
            />
         
            <input
              id="time"
              placeholder="Time"
              variant="standard"
              onChange={(e) => setTime(e.target.value)}
              className='BlogFormInput'
            />
         
            <br />
            <Button variant="contained" type="submit" className="buttonstyle">
              Upload
            </Button>

      </form>
    </div>
  );
}

export default TrainingCourses;


