import axios from 'axios'
import React, { useState } from 'react'
import './Blogs.css'
function Blogs() {
    const [title,setTitle]=useState("")
    const [content,setContent]=useState("")
    const handleSubmit=(e)=>{
        e.preventDefault();
        axios.post("http://localhost:5000/api/post/blogs/",{
            title:title,
            content:content
        }).then((res)=>{
            console.log(res)
        })
    }
  return (
    <div className='adminBlogContainer'>
        <form action="" onSubmit={handleSubmit} className='adminBlogForm'>
            <input type="text" placeholder='Title'value={title} onChange={(e)=>setTitle(e.target.value)} className='BlogFormInput'/>
            <br />
            <textarea name="" id="" cols="30" rows="5" className='BlogFormInput' placeholder='Contents' onChange={(e)=>setContent(e.target.value)} ></textarea>
            <button type='submit' className='buttonStyle'>submit</button>
        </form>
    </div>
  )
}

export default Blogs