import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "../Pages/about/About";
import Home from "../Pages/home/Home";
import Navbar from "../components/navbar/Navbar";
import Banner from "../components/banner/Banner";
import Footer from "../components/footer/Footer";
import Training from "../Pages/training/Training";
import EventsPage from "../Pages/events/EventsPage";
import SingleEvent from "../Pages/events/SingleEvent";
import Blogs from "../Pages/Blogs/Blogs";
import ViewMore from "../Pages/training/ViewMore";
import JoinUs from "../Pages/JoinUs/JoinUs";
import Pagination from "../components/pagination/Pagination";
import TrainingCourses from "../Admin/training courses/TrainingCourses";
import BlogsAdmin from "../Admin/blogs/Blogs"
import EventsAdmin from "../Admin/events/EventsAdmin";
import ImageGallery from "../components/Gallery/GalleryApi";
import ImageUploader from "../components/Gallery/GalleryPost";
import GalleryPage from "../Pages/gallery/GalleryPage";
import FormTest from "../components/popup/FormTest";
import NotFound from "../Pages/NotFound/Notfound";

function Navigate() {
  return (
    <div>
      <Router>
        <Routes>
        <Route exact path="/" element={<Home/>}></Route>
          <Route exact path="/navbar" element={<Navbar />}></Route>
          <Route exact path="/banner" element={<Banner/>}></Route> 
          <Route exact path="/about" element={<About />} />
          <Route exact path="/footer" element={<Footer/>} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/training" element={<Training/>} />
          <Route exact path="/events" element={<EventsPage/>} />
          <Route exact path="/event/page/:id" element={<SingleEvent/>} />
          <Route exact path="/blogs" element={<Blogs/>} />
          <Route exact path="/course" element={<ViewMore/>} />
          <Route exact path="/joinus" element={<JoinUs/>} />
          <Route exact path="/pagination" element={<Pagination/>} />
          <Route exact path="/admin/training" element={<TrainingCourses/>} />
          <Route exact path="/admin/blogs" element={<BlogsAdmin/>} />
          <Route exact path="/admin/events" element={<EventsAdmin/>} />
          <Route exact path="/gallery/api" element={<ImageGallery/>} />
          <Route exact path="/gallery/post" element={<ImageUploader/>} />
          <Route exact path="/gallery/page" element={<GalleryPage/>} />
          <Route exact path="/testform" element={<FormTest/>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default Navigate;
