import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Gallery.css";

function ImageGallery() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get("http://localhost:5000/image/get");
      console.log("response data", response);
      setImages(response.data?.images?.[0]);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <h1>Image Gallery</h1>
      <div className="galleryApiImage">
        <img
          src={`http://localhost:5000/${images?.imageData}`}
          alt={images.imageName}
        />
      </div>
    </div>
  );
}

export default ImageGallery;
