import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import "./About.css";
import soumya from "../../Assets/soumya.jpeg"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PopupAbout(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <img src={props.img} alt="" onClick={handleClickOpen} />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className="PopupDiv">
         

          <div className="TeamContents">
            
            <div className="teamPopupImg">
              <img
                src={soumya}
                alt=""
              />
            </div>
            <div className="title">
            <Button autoFocus color="inherit" onClick={handleClose} className="popupClose">
            <CloseIcon />
          </Button>
              <h2>Dr Sowmya N S</h2>
              <br />
              <h5>President</h5>
              <h6>EFOG-EBCOG, MRCOG, MRCPI, MCCG, MICOG, IBCLC, ALPI, DGO, PGDUS, DMAS, FMAS, MBBS</h6>
              <h6>Sr. Consultant – Obstetrics and Gynaecology</h6>
            </div>
          </div>
          <div className="AboutTeam">
            <p>
            A renowned OB GYN specialist from India, Dr Sowmya NS is an expert mentor and teacher and is well known for her skills in high-risk pregnancies, laparoscopic surgeries, infertility and IVF treatment, lactation and others. She will head the International Coordinating Committee of EBCOG (ICC EBCOG) as its first president.
            <br /><br />
            Dr Sowmya stepped into mentoring at a very young age and later on co-founded and launched her premier education platform StudyMEDIC in 2016. As the co-founder and COO of StudyMEDIC, she is popular among the candidates of various international medical membership and fellowship programs. 
            <br /><br />
            Dr Sowmya has attended various national and international congresses and presented research papers and presentations on various topics related to OB GYN. She has over 20 years of professional experience in dealing with various relevant surgical and medical procedures and management position, diagnosis and treatment of many gynaecological and obstetrical disorders, and more. Currently, she is working as a Senior Consultant Obstetrician and Gynaecologist at NYLE Super Speciality Hospital for Women and Children.
            <br /><br />
            She is the board member of many prestigious organizations and was also a part of numerous national and international conferences as an operating faculty and delivered several scientific presentations and lectures apart from conducting workshops and meets.
As the Co-founder, COO & Chief Mentor of StudyMEDIC Academy Pvt Ltd, she has students from all parts of the globe, and she also conducts regular awareness programs on TV, print media and other public forums.

   <br /><br />
            Specialities
            <ul>
              <li>	Obstetrics and Gynaecology (Specialized and Experienced in High-Risk Pregnancies)</li>
              <li>	Laparoscopic Surgeries</li>
              <li> 	Infertility & IVF Specialist</li>
              <li> 	Certified Lactation Consultant</li>
<br /><br />
            </ul></p>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default PopupAbout;
