import React from "react";
import "./Training.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { trainingData } from "./TrainingData";
import { Link } from "react-router-dom";
import RegisterPopup from "./RegisterPopup";

function Training() {
  return (
    <>
      <Navbar />
      <div>
        <div className="trainingBanner">
            <h1>Training</h1>
        </div>
    </div>
      <div className="training">
        {trainingData.map((item, index) => {
          return (
            <div className="trainingGrid" key={index}>
              <img
                src="https://studyplab.com/wp-content/uploads/2021/12/d.png"
                alt=""
              />
              <br />
              <ul>
                <li>
                  <MenuBookIcon className="trainingIcon" /> &nbsp;{item.course}
                </li>
                <li>
                  <AccountCircleIcon className="trainingIcon" /> &nbsp;{item.name}
                </li>
                <li>
                  <CalendarMonthIcon className="trainingIcon" /> &nbsp; {item.date}
                </li>
                <li>
                  <WatchLaterIcon className="trainingIcon" /> &nbsp; {item.time}
                </li>
              </ul>
              <div className="trainingViewButton">
                <Link to='/course'>
              <button className="viewButtonStyle">View More</button></Link>
              <RegisterPopup className={"trainingButtonStyle"} button={'Register now'}  title={"Register now"}/>
              
              </div>
            </div>
          );
        })}
      </div>
      <Footer />
    </>
  );
}

export default Training;
