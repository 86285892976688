import React, { useEffect } from "react";

import Grid from "@mui/material/Grid";

import "swiper/css";

import "./Gallery.css";

import GalleryProps from "./GalleryProps";
import axios from "axios";

import event1Image from "../../Assets/event1.jpg";
import bgImage from "../../Assets/event2.jpg";
import img4 from "../../Assets/event3.jpg";
import img1 from "../../Assets/gallery1.jpg";
import img5 from "../../Assets/gallery3.jpg";
import img6 from '../../Assets/gallery4.jpg'

function Gallery() {
  const GalleryData = [
    {
      img1: img1,
      img2: img5,
      img3: event1Image,
      img4: img4,
      img5: bgImage,
      img6:img6
    },
  ];
 
  // const [images, setImages] = useState([]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get("http://localhost:5000/image/get");
      console.log("response", response);

      // setImages(response.data.images);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="gallery">
      {GalleryData.map((data, index) => {
        return (
          <>
            <h1>Gallery</h1>
            <br />
            <br />
            <Grid container spacing={2} Key={index}>
              <Grid item sm={4} xs={12}>
                <GalleryProps data={data} />
              </Grid>
              {/* <Grid item sm={4} xs={12}>
                <GalleryProps data={data} />
              </Grid>
              <Grid item sm={4} xs={12}>
                <GalleryProps data={data} />
              </Grid> */}
            </Grid>
          </>
        );
      })}
    </div>
  );
}

export default Gallery;
