import React from "react";
import "./Partners.css";
import { Link } from "react-router-dom";
import logo1 from "../../Assets/icc-ebcog-logo.png";
import logo2 from "../../Assets/smc-logo.png";

function Partners() {
  return (
    <div className="partnersDiv">
      <h1>Our Partners</h1>
      <br /><br />
      <div className="logos">
        
        <Link to="/partners"  className="partnersImage" >
          <img src={logo1} alt=""/>

        </Link>
        <Link to="/partners" className="partnersImage">
          <img src={logo2} alt=""  />
        </Link>
      </div>
    </div>
  );
}

export default Partners;
