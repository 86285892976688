import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import img from "../../Assets/iccEventImage.jpg";
import "./Events.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

function Events() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="eventsContainer">
      <Link to="/events">
        <h1>Events</h1>
      </Link>
      <br />
      <br />
      <Grid container spacing={2} className="cards">
        <br />
        {/* <Grid item lg={2.5}md={3}sm={5}xs={10} className="eventsCard">
        <div data-aos="flip-right">
          <div className="eventsimage">
            <img src={img} alt="" />
          </div></div>
          <br />
          <div className="content">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </div>
          <br />
        </Grid>
        <Grid item lg={2.5} md={3}sm={5}xs={10} className="eventsCard">
        <div data-aos="flip-right">
          <div className="eventsimage">
            <img src={img} alt="" />
          </div></div>
          <br />
          <div className="content">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          
          </div>
          <br />
        </Grid> */}
        <Grid item lg={2.5} md={3} sm={5} xs={10} className="eventsCard">
          <div data-aos="flip-right">
            <div className="eventsimage">
              <Link to="/events">
                <img src={img} alt="" />
              </Link>
            </div>
          </div>
          <br />
          <div className="content">
            <Link to="/events">ICC EBCOG Launch @28th EBCOG Congress</Link>
          </div>
          <br />
        </Grid>
        {/* <Grid item lg={2.5}md={3}sm={5}xs={10}  className="eventsCard">
        <div data-aos="flip-right">
          <div className="eventsimage">
            <img src={img} alt="" />
          </div></div>
          <br />
          <div className="content">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </div>
          <br />
        </Grid> */}
      </Grid>
    </div>
  );
}

export default Events;
