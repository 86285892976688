import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Grid from "@mui/material/Grid";

import "swiper/css";
import "./Gallery.css";

import "swiper/css/effect-flip";
import "swiper/css/navigation";
import { EffectFlip, Navigation } from "swiper";

function GalleryProps(props) {
  const { data } = props;
  return (
    <div>
      <>
        <Grid container spacing={8}>
          <Grid item sm={11} xs={12}>
            <Swiper
              grabCursor={true}
              effect={"flip"}
              navigation={true}
              modules={[EffectFlip, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="gallerySlides">
                  <img src={data.img6} alt="" /> <br />
                  <p>28th EBCOG Congress</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="gallerySlides">
                  <img src={data.img2} alt="" /> <br />
                  <p>28th EBCOG Congress</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="gallerySlides">
                  <img src={data.img3} alt="" /> <br />
                  <p>28th EBCOG Congress</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="gallerySlides">
                  <img src={data.img1} alt="" /> <br />
                  <p>28th EBCOG Congress</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="gallerySlides">
                  <img src={data.img4} alt="" /> <br />
                  <p>28th EBCOG Congress</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="gallerySlides">
                  <img src={data.img5} alt="" /> <br />
                  <p>28th EBCOG Congress</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </Grid>
        </Grid>
      </>
    </div>
  );
}

export default GalleryProps;
