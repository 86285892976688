import React, { useState } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IconButton, Menu, MenuItem } from "@mui/material";
import "./Navbar.css";
import logo from '../../Assets/icc-ebcog-logo.png'


function Navbar() {
  const isSmallScreen = useMediaQuery("(max-width:940px)");

  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <header className="header">
      <Link to="/">
        <img src={logo} alt="" />
      </Link>

      {isSmallScreen ? (
        <div className="header_open">
          <IconButton color="inherit" onClick={handleMenuOpen}>
            <ViewHeadlineIcon className="close_icon" />
          </IconButton>
          <Menu
            className="menu"
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
          >
            <MenuItem className="menu_item">
              <Link to="/" onClick={handleMenuClose}>
                <CloseIcon className="close_icon" />
              </Link>
            </MenuItem>
            <MenuItem className="menu_item">
            <Link to='/'>
          <span>Home</span>
          </Link>
            </MenuItem>
            <MenuItem className="menu_item">
            <Link to='/about'>
          <span>About</span>
          </Link>
            </MenuItem>
            <MenuItem className="menu_item">
            <Link to={'/training'}>
          <span>Training</span></Link>
            </MenuItem>
            <MenuItem className="menu_item">
            <Link to={'/joinus'}>
           <span>Become a Partner</span>
           </Link>
            </MenuItem>
            <MenuItem className="menu_item">
            <Link to={'/events'}>
          <span>Events</span></Link>
            </MenuItem>
            <MenuItem className="menu_item">
            <Link to={'/blogs'}>
          <span>Blogs</span></Link>
            </MenuItem>
            <MenuItem className="menu_item">
              <button className="nav-btn  nav-close-btn"></button>
            </MenuItem>
          </Menu>
        </div>
      ) : (
        <div className="headerMain">
          <Link to='/'>
          <span>Home</span>
          </Link>
          &nbsp;&nbsp;
          <Link to='/about'>
          <span>About</span>
          </Link>
          &nbsp;&nbsp;
          <Link to={'/training'}>
          <span>Training</span></Link>
          &nbsp;&nbsp;
           <Link to={'/joinus'}>
           <span>Become a Partner</span>
           </Link>
          &nbsp;&nbsp;
          <Link to={'/events'}>
          <span>Events</span></Link>
          &nbsp;&nbsp;
          <Link to='/blogs'>
          <span>Blogs</span></Link>
          &nbsp;&nbsp;
        </div>
      )}
    </header>
  );
}

export default Navbar;
