import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./Branch.css";
import HandshakeIcon from "@mui/icons-material/Handshake";
import BusinessIcon from "@mui/icons-material/Business";
import MenuBookIcon from "@mui/icons-material/MenuBook";

import AOS from "aos";
import "aos/dist/aos.css";

function Branch() {
  const [counter, setCounter] = useState(false);
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <ScrollTrigger
      onEnter={() => setCounter(true)}
      onExit={() => setCounter(false)}
    >
      <div className="conterOutsideDiv">
        <h1>Our Key Strengths</h1>
        <div className="counters">
          <div data-aos="flip-right">
            <div className="counterDiv">
           
              <HandshakeIcon className="counterIcon" />
              <br />
              <span className="counterStyle">
                {counter && (
                  <CountUp start={1} end={2} duration={4} delay={1} />
                )}
                +
              </span>
              <h4>Partners</h4>
            </div>
          </div>
          <div data-aos="flip-right">
            <div className="counterDiv">
         
              <BusinessIcon className="counterIcon" />
              <br />
              <span className="counterStyle">
                {counter && (
                  <CountUp start={2} end={10} duration={4} delay={1} />
                )}
                +
              </span>
              <h4>Branches</h4>
            </div>
          </div>
          <div data-aos="flip-right">
            <div className="counterDiv">
           
              <MenuBookIcon className="counterIcon" />
              <br />
              <span className="counterStyle">
                {counter && (
                  <CountUp start={20} end={45} duration={4} delay={1} />
                )}
                +
              </span>
              <h4>Courses</h4>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}

export default Branch;
