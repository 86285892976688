
import React from "react";
import TextField from "@mui/material/TextField";
import "./Popup.css";

import emailjs from "emailjs-com";

import Grid from "@mui/material/Grid";

import { FormControl, InputLabel, MenuItem, Select, Button, Container } from "@mui/material";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import * as Yup from 'yup'

const Form = (props) => {
  // const [imgUrl, setImgUrl] = useState('')
  // const [cvUrl, setCvUrl] = useState('')

  const initialValues = {
    firstName: '',
    location: '',
    email: '',
    phone: '',
    image: '',
    cv: '',
    workingLocation: '',
    workingOrganisation: '',
    likeToContribute: '',
    reqReason: '',
    lastName: ''
  }
  const validationSchema = Yup.object({
    firstName: Yup.string().required('Please enter your firstName'),
    location: Yup.string().required('Please enter the location'),
    email: Yup.string().email('Invalid email address').required('Enter your email'),
    phone: Yup.string().required('This field is required'),
    // image: Yup.mixed().required('This field is required'),
    // cv: Yup.mixed().required('This field is required'),
    workingLocation: Yup.string().required('This field is required'),
    workingOrganisation: Yup.string().required('This field is required'),
    likeToContribute: Yup.string().required('This field is required'),
    reqReason: Yup.string().required('This field is required'),
  });
  const handleSubmit = (values) => {
    console.log(values)
    const {email, firstName, lastName, likeToContribute, location, phone, reqReason, workingLocation, workingOrganisation} = values;
    const templateParams = {
      email,
      firstName,
      lastName,
      likeToContribute,
      location,
      phone,
      reqReason,
      workingLocation,
      workingOrganisation,
    }
  //   var templateParams = {
  //     name: 'James',
  //     notes: 'Check this out!'
  // };
    emailjs.send(
      'service_bc8l8bn',
      'template_9lah555',
      templateParams,
      '_s3-pu44Pysepz3VQ'
    ).then((res) => {
      console.log(res);
      alert()
    }).catch((err) => console.log(err.message))
  }
  
  return (
    <div className="PartnerForm">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >{(formik) => (
        <FormikForm>
          <Grid container spacing={1} className="filterContainer">
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Field
                as={TextField}
                id="outlined-basic"
                name="firstName"
                label="First Name"
                variant="outlined"
                className="inputField"
                // onChange={(e) => setName(e.target.value)}
                size='small'
              />
              <Container sx={{ width: '100%', height: '10px', padding: '0 !important' }}>
                <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name='firstName' component='div' />
              </Container>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Field
                as={TextField}
                id="outlined-basic"
                name="lastName"
                label="Last Name"
                variant="outlined"
                className="inputField"
                // onChange={(e) => setLastName(e.target.value)}
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Field
                as={TextField}
                id="outlined-basic"
                name="email"
                label="Email"
                variant="outlined"
                className="inputField"
                // onChange={(e) => setEmail(e.target.value)}
                size='small'
              />
              <Container sx={{ width: '100%', height: '10px', padding: '0 !important' }}>
                <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name='email' component='div' />
              </Container>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Field
                as={TextField}
                id="outlined-basic"
                name="location"
                label="Location"
                variant="outlined"
                className="inputField"
                // onChange={(e) => setLocation(e.target.value)}
                size='small'
              />
              <Container sx={{ width: '100%', height: '10px', padding: '0 !important' }}>
                <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name='location' component='div' />
              </Container>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Field
                as={TextField}
                id="outlined-basic"
                name="phone"
                label="Phone"
                variant="outlined"
                className="inputField"
                // onChange={(e) => setPhone(e.target.value)}
                size='small'
              />
              <Container sx={{ width: '100%', height: '10px', padding: '0 !important' }}>
                <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name='phone' component='div' />
              </Container>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Field
                as={TextField}
                id="outlined-basic"
                name="workingLocation"
                label="Working Location"
                variant="outlined"
                className="inputField"
                // onChange={(e) => setWorkingLocation(e.target.value)}
                size='small'
              />
              <Container sx={{ width: '100%', height: '10px', padding: '0 !important' }}>
                <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name='workingLocation' component='div' />
              </Container>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Field
                as={TextField}
                id="outlined-basic"
                name="workingOrganisation"
                label="Working Organisation"
                variant="outlined"
                className="inputField"
                // onChange={(e) => setWorkingOrganisation(e.target.value)}
                size='small'
              />
              <Container sx={{ width: '100%', height: '10px', padding: '0 !important' }}>
                <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name='workingOrganisation' component='div' />
              </Container>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormControl sx={{ width: '98%' }}>
                <InputLabel size="small" id="demo-simple-select-label">How do you like to contribute</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="How do you like to contribute"
                  onChange={(e) => formik.setFieldValue('likeToContribute', e.target.value)}
                  size='small'
                >
                  <MenuItem value='As a member'>As a member</MenuItem>
                  <MenuItem value='Mentor'>Mentor</MenuItem>
                  <MenuItem value='Organizer'>Organizer</MenuItem>
                  <MenuItem value='Collaborations'>Collaborations</MenuItem>
                  <MenuItem value='Branding'>Branding</MenuItem>
                  <MenuItem value='Community Building'>Community Building</MenuItem>
                </Select>
              </FormControl>
              <Container sx={{ width: '100%', height: '10px', padding: '0 !important' }}>
                <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name='likeToContribute' component='div' />
              </Container>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Field
                as={TextField}
                sx={{ width: '98%', mt: 1 }}
                id="outlined-multiline-static"
                label="Reason for request"
                multiline
                rows={3}
                // onChange={(e) => setReqReason(e.target.value)}
                name='reqReason'
              />
              <Container sx={{ width: '100%', height: '10px', padding: '0 !important' }}>
                <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name='reqReason' component='div' />
              </Container>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={5.8}
              xl={6}
              className={props.className}
            >
              <label className="custom-file-upload">
                <input type="file" onChange={(event) => {
                     formik.setFieldValue('image', event.target.files[0]);
                    //  handleFileInputChange(event, setImgUrl)
                }} />
                Upload image
                <Container sx={{ width: '100%', height: '10px', padding: '0 !important' }}>
                <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name='image' component='div' />
              </Container>
              </label>
              
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={5.6}
              xl={6}
              className={props.className}
            >

              <label className="custom-file-upload">
                <input type="file" onChange={(event) => {
                    formik.setFieldValue('cv', event.target.files[0]);
                }} />
                Upload CV
                <Container sx={{ width: '100%', height: '10px', padding: '0 !important' }}>
                <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name='cv' component='div' />
              </Container>
              </label>

              
            </Grid>
            <Grid item xs={12} xl={12}>
              <Button type='submit' sx={{width: '100%'}} variant='contained'>Submit</Button>
            </Grid>
          </Grid>
        </FormikForm>
      )}
      </Formik>
    </div>
  );
};
export default Form;



