import React, { useState } from "react";
import "./Blogs.css";
import Grid from "@mui/material/Grid";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { BlogsData } from "./BlogsData";
import Pagination from "../../components/pagination/Pagination";


function Blogs() {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);
  const indexOfLastBlog = currentPage * postsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - postsPerPage;
  const currentBlogs = BlogsData.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div>
      <Navbar />
      <div className="blogsBanner">{/* <h1>Blogs</h1> */}</div>
      <div className="blogsContainer1">
        <Grid container spacing={2} className="blog_container">
        {/* <Grid item xs={12} sm={12} md={2} lg={2}>
              <Filter />
            </Grid> */}
          {currentBlogs.map((item, id) => {
            return (
              <Grid item xs={12} sm={6} md={6} lg={5} key={id}>
                <div>
                  <div className="blogGrid">
                    <img src={item.img} alt="" />
                    <br />
                    <p>{item.content}</p>
                  </div>
                </div>
                <br /><br />
              </Grid>
              
            );
          })}
          
        </Grid>
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={BlogsData.length}
          paginate={paginate}
        />
      </div>

      <Footer />
    </div>
  );
}

export default Blogs;
