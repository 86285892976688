import axios from 'axios'
import React, { useState } from 'react'
import "../blogs/Blogs.css"
function EventsAdmin() {
    const [title,setTitle]=useState("")
    const [content,setContent]=useState("")
    const [date,setDate]=useState("")
    const [time,setTime]=useState("")
    const handleSubmit=(e)=>{
        e.preventDefault();
        axios.post("http://localhost:5000/api/post/events/",{
            title:title,
            content:content,
            date:date,
            time:time
        }).then((res)=>{
            console.log(res)
        })
    }
  return (
    <div className='adminBlogContainer'>
        <form action="" onSubmit={handleSubmit} className='adminBlogForm'>
            <input type="text" placeholder='Title'value={title} onChange={(e)=>setTitle(e.target.value)} className='BlogFormInput'/>
            <br />
            <input type="text" placeholder='Date'value={date} onChange={(e)=>setDate(e.target.value)} className='BlogFormInput'/>
            <br />
            <input type="text" placeholder='Time'value={time} onChange={(e)=>setTime(e.target.value)} className='BlogFormInput'/>
            <br />
            <textarea name="" id="" cols="30" rows="5" className='BlogFormInput' placeholder='Contents' onChange={(e)=>setContent(e.target.value)} ></textarea>
            <button type='submit' className='buttonStyle'>submit</button>
        </form>
    </div>
  )
}

export default EventsAdmin