export const eventsData = [
  {
    Title:"28th EBCOG Congress",
    id:'1',
    Date:'May 20 2023',
    seconds:'80',
    date:'May 30 2023 11:30:00',
    img: "https://www.maritzglobalevents.com/-/media/Maritz/Project/MGEV2/Heros/corporate-meetings-hero.ashx",
    content:
      "ICC EBCOG will conduct various webinars and training programs as part of its mission and vision to uplift the skills of medical professionals. Stay tuned here for updates.",
   }
 // {
    // id:"2",
    // date:'0',
    // Date:'May 20 2023',
    // seconds:'0',
    // img: "https://www.maritzglobalevents.com/-/media/Maritz/Project/MGEV2/Heros/corporate-meetings-hero.ashx",
    // content:
    //   "This online course is for practicing OB-GYN physicians who are interested in training for endometriosis surgery. This master course will be over 3 months of extensive hybrid and rapid progression of sessions including lectures, Q&A, and live surgeries with clinical attachment ensuring full coverage on tackling patients with endometriosis.",
  //},
  // {
  //   id:"3",
  //   Date:'July 10 2023',
  //   seconds:'90',
  //   date:'July 10 2023 01:00:00',
  //   img: "https://businesseventstokyo.org/assets/img/index/img_team_01.jpg",
  //   content:
  //     "The Part 1 KBA Exam will be held 13th May 2023. The exam assess knowledge (KBA) and is computer based. It will be an online exam with no venue. Exam will be a Remotely and Virtually Invigilated Exam (RIVE). Passing this exam in necessary to sit the part 2 exam. For more information: Exam",
  // },
  // {
  //   id:"4",
  //   Date:'July 10 2023',
  //   seconds:'90',
  //   date:'July 10 2023 01:00:00',
  //   img: "https://businesseventstokyo.org/assets/img/index/img_team_01.jpg",
  //   content:
  //     "The Part 1 KBA Exam will be held 13th May 2023. The exam assess knowledge (KBA) and is computer based. It will be an online exam with no venue. Exam will be a Remotely and Virtually Invigilated Exam (RIVE). Passing this exam in necessary to sit the part 2 exam. For more information: Exam",
  // },
];
