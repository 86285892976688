import React, { useEffect } from "react";
import "./About.css";
import Grid from "@mui/material/Grid";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import img1 from "../../Assets/soumya.jpeg";
import img2 from "../../Assets/about.jpg";
import img3 from "../../Assets/tazeen.jpeg"
import img4 from "../../Assets/dhavasree.jpeg"

// import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-cards";
// import { EffectCards } from "swiper";
import { GalleryData } from "../../components/Gallery/GalleryData";
import PopupAbout from "./PopupAbout";
import Popup2 from "./popup2";
import Popup3 from "./Popup3";

function About() {
  const data = { ...GalleryData };
  console.log("about data", data);
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="aboutBanner">
        <h1>About Us</h1>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={7.5} className="aboutDiv1">
          <div data-aos="fade-up" data-aos-easing="ease-in-sine">
            <div className="about-contents">
              <h2>About International Coordinating Committee (ICC) EBCOG</h2>
              <br />
              <p className="aboutDescription">
                The International Coordinating Committee (ICC) is an official body
                of EBCOG comprising OB GYN experts from across the globe. The
                official body was formed in 2023 as an independent organization
                which will work towards the skill enhancement of OB GYN
                professionals across the globe.
                <br />
                <br />
                The committee will remain independent but will work closely in
                association with the parent organization EBCOG and will bear the
                same vision and mission. ICC will primarily remain committed to
                promote the highest possible standards of care to women, unborn
                and new-born. This will help us ensure and improve their health
                standards.
                <br />
                <br />
                It as the prime responsibility to spread the vision and mission
                of EBCOG to the farthest countries across the globe. It also
                aims to conduct various training programs for uplifting the
                skills of medical professionals especially the OB GYN.
              </p>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4.5} className="aboutDiv1">
          <div data-aos="fade-down" data-aos-easing="ease-in-sine">
            <img className="aboutImage" src={img2} alt="" />
          </div>
        </Grid>
{/* 
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="swiperAboutContainer"
        >
          <div className="aboutDiv2">
            <div className="swiperAbout">
              <Swiper
                grabCursor={true}
                effect={"cards"}
                creativeEffect={{
                  prev: {
                    shadow: true,
                    origin: "left center",
                    translate: ["-5%", 0, -200],
                    rotate: [0, 100, 0],
                  },
                  next: {
                    origin: "right center",
                    translate: ["5%", 0, -200],
                    rotate: [0, -100, 0],
                  },
                }}
                modules={[EffectCards]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img
                    src="https://ufg-heroku.s3.amazonaws.com/tbausa/prod/public/Diverse-Group-of-Business-People-900x500.jpg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://localccprocessing.com/wp-content/uploads/2017/03/professional-business.jpg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://www.trade.gov/sites/default/files/styles/card_teaser/public/2021-06/Diversity%20Asian%20Businesspeople.jpeg?itok=MlE4fhKN"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://thumbs.dreamstime.com/b/business-people-diversity-team-corporate-professional-concept-50416149.jpg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://thumbs.dreamstime.com/b/young-black-woman-seated-team-meeting-facing-camera-women-93538261.jpg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    alt=""
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="aboutDiv2Content">
              <h2>Awards & Recognitions</h2>
              <br />
              <p>
                StudyMEDIC as a company has claimed innumerous recognitions for
                the service offering to the medical education across the globe
                but as a family each member are one of our greatest
                contributions that StudyMEDIC can proudly represent to the
                medical fraternity.
              </p>
            </div>
          </div>
        </Grid> */}

        <Grid item xs={12} sm={12} md={12} lg={12} className="teamGrid">
          <h1>Meet Our Team</h1>
          <br />
          <h2>Our Key Position Holders</h2>
          <div className="team">
            <div data-aos="flip-left" aos-duration="3500">
              <div className="team1">
                <PopupAbout img={img1} />
                <h3>Dr. Sowmya N S</h3>
                <p style={{fontSize: '13px'}}>EFOG-EBCOG, MRCOG, MRCPI, MCCG, MICOG, IBCLC, ALPI, DGO, PGDUS, DMAS, FMAS, MBBS</p>
                <p style={{fontWeight: 'bold'}}>President</p>
              </div>
            </div>
            <div data-aos="flip-left" aos-duration="1500">
              <div className="team1">
                <Popup2 img={img3} />
                <h3>Dr. Tazeen Ashraf</h3>
                <p style={{fontSize: '13px'}}>EFOG-EBCOG, FCPS OBG, MBBS</p>
                <p style={{fontWeight: 'bold'}}>Vice President </p>
              </div>
            </div>
            <div data-aos="flip-right">
              <div className="team1">
                <Popup3 img={img4} />
                <h3> Dr. Dhavashree V</h3>
                <p>EFOG-EBCOG, MRCOG, DGO, MBBS</p>
                <p style={{fontWeight: 'bold'}}>Treasurer</p>
              </div>
            </div>
            {/* <div data-aos="flip-right">
              <div className="team1">
                <PopupAbout img={img1} />
                <h3>Name</h3>
                <p>Position</p>
              </div>
            </div>*/}
          </div> 
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="aboutDiv3">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine">
              <div className="mission">
                <span>
                  <AdsClickIcon className="MissionIcon" />
                </span>
                <h2> OUR MISSION</h2>
                <br />
                <p>
                  The Committee holds it as the prime responsibility to spread
                  the vision and mission of EBCOG to the farthest countries
                  across the globe. It also aims to conduct various training
                  programs for uplifting the skills of medical professionals
                  especially the OB GYN.
                </p>
              </div>
            </div>
            <div data-aos="zoom-in" data-aos-easing="ease-in-sine">
              <div className="vision">
                <GolfCourseIcon className="MissionIcon" />
                <h2>OUR VISION</h2>
                <br />
                <p>
                  The International Coordinating Committee - ICC EBCOG follows the
                  same mission and vision of EBCOG. The committee aims to
                  promote the highest possible standards of care to women,
                  unborn and newborn so as to ensure and improve their health
                  standards.
                </p>
              </div>
            </div>
            <div data-aos="fade-down" data-aos-easing="ease-in-sine">
              <div className="values">
                <AutoAwesomeIcon className="MissionIcon" />
                <h2> OUR CORE VALUES</h2>
                <br />
                <p>
                  ICC EBCOG always upholds providing the best healthcare
                  services to women as its prime responsibility. We uphold moral
                  ethics while providing the best care and support to women
                  round through their cycle of life, enabling them to lead a
                  happy and healthy life.
                </p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}

export default About;
