import React from "react";
import video from "../../Assets/BG-video.mp4";
import "./Banner.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
function Banner() {
  const navigate = useNavigate();
  return (
    <div style={{ position: "relative" }} className="bannerVideo">
      <video
        src={video}
        type="video/mp4"
        autoPlay
        loop
        muted="true"
        className="bannerVideo"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          objectFit: "cover",
          zIndex: -1,
        }}
      />
      <div className="bannerContent">
        <h2
          style={{
            fontWeight: "bolder",
            fontSize: "25px",
            color: "white",
            textShadow: "2px 2px #242424",
          }}
        >
          Welcome to the official website of International Coordinating
          Committee - ICC EBCOG.
        </h2>
        <br />

        <Button
          onClick={() => navigate("/joinus")}
          variant="contained"
          size="large"
        >
          Join Us
        </Button>
      </div>
    </div>
  );
}

export default Banner;
