import React, { useState } from "react";
import "./Footer.css";
import img from "../../Assets/icc-ebcog-logo.png";
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";

function Footer() {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = React.useState(false);

  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const subscribe = async (e) => {
    e.preventDefault();
    if (email.trim() === "") {
      return ; // Do not subscribe if the email is empty
    }
     if (validateEmail(email)) {
    try {
      setSubscribed((show) => !show)
      const response = await fetch(
        "https://v1.nocodeapi.com/shahidajubil/google_sheets/CPGjfNQmzvLNdodg?tabId=subscribers",
        {
          method: "POST",
          headers: {
            "content-Type": "application/json",
          },
          body: JSON.stringify([[email, new Date().toLocaleString]]),
        }
      );
      await response.json();
      console.log("response", response);

      setEmail("");
    } catch (error) {
      console.log(error);
    }
  }}
  return (
    <>
      <div className="footerMain">
        <div className="logo">
          <img src={img} alt="" />
        </div>
        <div className="firstColumn" style={{width: '130px'}}>
          <ul>
            <Link to="/">
              <li>Home</li>
            </Link>
            <Link to="/about">
              {" "}
              <li>About us</li>
            </Link>
            <Link to="/events">
              <li>Events</li>
            </Link>
            <Link to="/training">
              <li>Training</li>
            </Link>
            {/* <Link to="/training"> */}
              <p>Privacy Policy</p>
            {/* </Link> */}
          </ul>
        </div>
        <div className="secondColumn">
          <ul>
            <li>Contact us</li>
            <li>Location</li>
            <li>Subscribe</li>

            <li className="subscribeInput">
              <FormControl
              className="subscribeInputField"
                sx={{ m: 1, backgroundColor: "white" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment" >
                  Enter your email
                </InputLabel>
                <OutlinedInput
                required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="outlined-adornment"
                  type="text"
                  endAdornment={
                   
                    <InputAdornment position="end">
                      <span className="subscribeButton" onClick={subscribe}>
                      {subscribed ? "subscribed" : "subscribe"}
                      </span>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Footer;
