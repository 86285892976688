export const BlogsData = [
    {
      id:'1',
      Date:'May 30 2023',
      seconds:'80',
      date:'May 30 2023 11:30:00',
      img: "https://youthvillage.co.zw/wp-content/uploads/Event-Management-Company.jpg",
      content:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumab quasi labore, animi temporibus, natus aspernatur consequunturquaerat rerum enim quo officia nesciunt cumque quis fugaperspiciatis sunt facilis vero?'
    },
    {
      id:"2",
      date:'June 20 2023 04:15:00',
      Date:'June 20 2023',
      seconds:'60',
      img: "https://youthvillage.co.zw/wp-content/uploads/Event-Management-Company.jpg",
      content:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumab quasi labore, animi temporibus, natus aspernatur consequunturquaerat rerum enim quo officia nesciunt cumque quis fugaperspiciatis sunt facilis vero?'
    },
    {
      id:"3",
      Date:'July 10 2023',
      seconds:'90',
      date:'July 10 2023 01:00:00',
      img: "https://youthvillage.co.zw/wp-content/uploads/Event-Management-Company.jpg",
      content:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumab quasi labore, animi temporibus, natus aspernatur consequunturquaerat rerum enim quo officia nesciunt cumque quis fugaperspiciatis sunt facilis vero?'
    },
    {
      id:'1',
      Date:'May 30 2023',
      seconds:'80',
      date:'May 30 2023 11:30:00',
      img: "https://youthvillage.co.zw/wp-content/uploads/Event-Management-Company.jpg",
      content:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumab quasi labore, animi temporibus, natus aspernatur consequunturquaerat rerum enim quo officia nesciunt cumque quis fugaperspiciatis sunt facilis vero?'
    },
    {
      id:"2",
      date:'June 20 2023 04:15:00',
      Date:'June 20 2023',
      seconds:'60',
      img: "https://youthvillage.co.zw/wp-content/uploads/Event-Management-Company.jpg",
      content:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumab quasi labore, animi temporibus, natus aspernatur consequunturquaerat rerum enim quo officia nesciunt cumque quis fugaperspiciatis sunt facilis vero?'
    },
    {
      id:"3",
      Date:'July 10 2023',
      seconds:'90',
      date:'July 10 2023 01:00:00',
      img: "https://youthvillage.co.zw/wp-content/uploads/Event-Management-Company.jpg",
      content:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumab quasi labore, animi temporibus, natus aspernatur consequunturquaerat rerum enim quo officia nesciunt cumque quis fugaperspiciatis sunt facilis vero?'
    },
    {
      id:'1',
      Date:'May 30 2023',
      seconds:'80',
      date:'May 30 2023 11:30:00',
      img: "https://youthvillage.co.zw/wp-content/uploads/Event-Management-Company.jpg",
      content:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumab quasi labore, animi temporibus, natus aspernatur consequunturquaerat rerum enim quo officia nesciunt cumque quis fugaperspiciatis sunt facilis vero?'
    },
    {
      id:"2",
      date:'June 20 2023 04:15:00',
      Date:'June 20 2023',
      seconds:'60',
      img: "https://youthvillage.co.zw/wp-content/uploads/Event-Management-Company.jpg",
      content:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumab quasi labore, animi temporibus, natus aspernatur consequunturquaerat rerum enim quo officia nesciunt cumque quis fugaperspiciatis sunt facilis vero?'
    },
    {
      id:"3",
      Date:'July 10 2023',
      seconds:'90',
      date:'July 10 2023 01:00:00',
      img: "https://youthvillage.co.zw/wp-content/uploads/Event-Management-Company.jpg",
      content:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumab quasi labore, animi temporibus, natus aspernatur consequunturquaerat rerum enim quo officia nesciunt cumque quis fugaperspiciatis sunt facilis vero?'
    },
    {
      id:'1',
      Date:'May 30 2023',
      seconds:'80',
      date:'May 30 2023 11:30:00',
      img: "https://youthvillage.co.zw/wp-content/uploads/Event-Management-Company.jpg",
      content:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumab quasi labore, animi temporibus, natus aspernatur consequunturquaerat rerum enim quo officia nesciunt cumque quis fugaperspiciatis sunt facilis vero?'
    },
    {
      id:"2",
      date:'June 20 2023 04:15:00',
      Date:'June 20 2023',
      seconds:'60',
      img: "https://youthvillage.co.zw/wp-content/uploads/Event-Management-Company.jpg",
      content:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumab quasi labore, animi temporibus, natus aspernatur consequunturquaerat rerum enim quo officia nesciunt cumque quis fugaperspiciatis sunt facilis vero?'
    },
    {
      id:"3",
      Date:'July 10 2023',
      seconds:'90',
      date:'July 10 2023 01:00:00',
      img: "https://youthvillage.co.zw/wp-content/uploads/Event-Management-Company.jpg",
      content:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumab quasi labore, animi temporibus, natus aspernatur consequunturquaerat rerum enim quo officia nesciunt cumque quis fugaperspiciatis sunt facilis vero?'
    },
  ];
  