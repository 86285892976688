import React, { useEffect } from "react";
import Popup from "../../components/popup/Popup";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./JoinUs.css";
import CellTowerIcon from "@mui/icons-material/CellTower";
import Aos from "aos";
import banner from '../../Assets/becomeAPartner.jpg'

function JoinUs() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Navbar />
      <div className="joinUs">
        <div className="joinImage">
          <img
            src={banner}
            alt=""
            style={{objectFit: 'cover', height: '630px', opacity: '0.5'}}
          />
          {/* <div style={{position: 'absolute', bottom: 0, left: 0, width: '100%', height: '-64', background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 3))'}}></div> */}
        </div>
        <div className="joinContents">
          <p >
            StudyMEDIC is the sole academic partner of EBCOG for conducting
            courses for EFOG-EBCOG courses globally. The treaty is signed for
            five years which will end in 2027. The organization is known for its
            quality courses and study materials coupled with top-notch mentoring
            which remain the pillars of their success.
            With the association with EBCOG, StudyMEDIC remains committed to
              promote and make the European Fellowship in Obstetrics and
              Gynaecology (EFOG) program to the nook and corners of the world.
              
          </p>
          <div>
            {" "}
            <p>
            The fellowship is intended to help the OB GYN professionals to
              enhance their skills and become better clinicians.

              The European Board & College of Obstetrics and Gynaecology (EBCOG) is another prime partner of the ICC. 
            </p>
            <br />
            <br />
            <Popup
              className={"buttonStyle"}
              button={"Become a Partner"}
              title={"Become a Partner"}
            />
          </div>
        </div>
        <div className="partnerMerits">
          <h2 style={{ textAlign: "center" }}>Opportunities To Partner</h2>
          <div className="becomePartnerDiv3">
            <div data-aos="fade-down" data-aos-easing="ease-in-sine">
              <div className="partnerCard">
                <span>
                  <CellTowerIcon className="MissionIcon" />
                </span>
                <h2> Network</h2>
                <br />
                <p>
                  To build a new era of sustainable online medical educational
                  platform with advanced technological integrations enabling in
                  creating reputed clinicians across the globe.
                </p>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-easing="ease-in-sine">
              <div className="partnerCard">
                <span>
                  <CellTowerIcon className="MissionIcon" />
                </span>
                <h2> Security</h2>
                <br />
                <p>
                  To build a new era of sustainable online medical educational
                  platform with advanced technological integrations enabling in
                  creating reputed clinicians across the globe.
                </p>
              </div>
            </div>
            <div data-aos="fade-down" data-aos-easing="ease-in-sine">
              <div className="partnerCard">
                <span>
                  <CellTowerIcon className="MissionIcon" />
                </span>
                <h2> Achievements</h2>
                <br />
                <p>
                  To build a new era of sustainable online medical educational
                  platform with advanced technological integrations enabling in
                  creating reputed clinicians across the globe.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default JoinUs;
