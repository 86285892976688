import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'

function GalleryPage() {
  return (
    <div>
      <Navbar/>
      <div className="joinImage">
          <img
            src="https://live4christnetworks.com/wp-content/uploads/2015/12/50dadf9ca315cPartnership-In-Ministry.jpg"
            alt=""
          />
        </div>
        <h1>Gallery</h1>
        <h5>28th EBCOG Congress @ Krakow, Poland</h5>
            <p>
            In 2023, the 28th European Congress of EBCOG (European Board & College of Obstetrics and Gynaecology) was held in Poland, at the Royal City Krakow.
            <br />
            During the Congress, doctors not only from all over Europe but also from across the globe will meet to listen to scholarly presentations by the most eminent international specialists. The congress had many specialized thematic blocks, as well as sessions dedicated to young doctors, doctors undergoing specialisation training.
            <br />
            Participation in the Congress provided a unique opportunity for international exchange of experience.
            </p>
        <Footer/>
    </div>
  )
}

export default GalleryPage