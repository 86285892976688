import React from 'react'
import { useParams } from "react-router-dom";
import { eventsData } from './EventsData';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import RegisterPopup from '../training/RegisterPopup';

function SingleEvent() {
  const { id } = useParams();
  const event = eventsData.find((blog) => blog.id === id);
  return (
    <div >
      <Navbar/>
      <div className="trainingBanner" >
            <h1>Events</h1>
        </div>
      <div className="singleEvent">
        <div className="singleEventContainer">
        <img src={event.img} alt="" />
        <br />
        <p>{event.content}</p>
        <br />
        <RegisterPopup className={"trainingButtonStyle"} button={'Register now'}  title={"Register now"}/>
        </div>
        </div>
        <Footer/>
    </div>
  )
}

export default SingleEvent